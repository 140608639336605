<template>
  <base-page-item>
    <v-timeline-item
      left
      class="task-item pa-3"
      :color="active ? 'blue lighten-2' : 'grey lighten-2'"
      :icon-color="active ? 'blue lighten-2' : 'grey lighten-2'"
      small
    >
      <template #opposite>
        <div class="text-left mb-0">
          <span class="font-weight-bold mr-1">Created at: </span>{{ task.created_at | fromNow }} ago
        </div>
        <div class="text-left mb-0">
          <span class="font-weight-bold mr-1">Pending tasks: </span>{{ pendingTasks }}
        </div>
      </template>
      <v-row
        align="center"
        class="spacer"
        no-gutters
        justify="center"
      >
        <v-col cols="12">
          <div class="text-left mb-0">
            <span class="font-weight-bold mr-1">Task status: </span>
            <span :class="color"> {{ task.status }} </span>
          </div>
          <div class="text-left mb-0">
            <span class="font-weight-bold mr-1">Wait error: </span>
            <span class="blue-grey--text">{{ task.wait_error || 'none' }}</span>
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>
  </base-page-item>
</template>

<script>
  import BasePageItem from '@/components/base/BasePageItem';
  import { fromNowFilterMixin } from '@/mixins/fromNowFilterMixin';

  export default {
    name: 'TaskItem',
    components: {
      BasePageItem,
    },
    mixins: [fromNowFilterMixin],
    props: {
      task: {
        type: Object,
        default: () => {},
      },
      active: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      result () {
        return this.task.result || this.task.data?.result || '';
      },
      pendingTasks () {
        let count = this.task.child_tasks.reduce((acc, task) => {
          acc += task.child_tasks.length;
          return acc;
        }, 0);
        if (this.task.bulk_connect) {
          count++;
        }
        if (this.task.fetch_api_html_tasks) {
          count += this.task.fetch_api_html_tasks.length;
        }
        if (this.task.ai_employee_li_tasks) {
          count += this.task.ai_employee_li_tasks.length;
        }
        return count;
      },
      color () {
        let colors;
        switch (this.task.status) {
        case 'done':
          colors = 'green--text text--darken-1';
          break;

        // case 'wait':
        //   colors = 'deep-purple--text';
        //   break;
        case 'failed':
          colors = 'red--text';
          break;
        default:
          colors = 'blue-grey--text';
          break;
        }
        return colors;
      },
    },
  };
</script>

<style scoped lang="scss">

.task-item {
  transition-duration: .5s;
  cursor: pointer;

  &:hover {
    background: rgba(black, .03);
  }
}

</style>
